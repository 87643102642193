/*
 * Playtype Foundry
 * Berlingske Serif Medium
 *
 */

@font-face {
  font-family: 'Berlingske Serif';
  src: url('../../assets/fonts/berlingske/BerlingskeSerif-Md.eot');
  src: url('../../assets/fonts/berlingske/BerlingskeSerif-Md.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/berlingske/BerlingskeSerif-Md.woff2')
      format('woff2'),
    url('../../assets/fonts/berlingske/BerlingskeSerif-Md.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
