@font-face {
  font-family: 'GT America Thin';
  src: url('../../assets/fonts/gt-america/GT-America-Standard-Thin.eot');
  src: local('GT America Thin'), local('GT-America-Standard-Thin'),
    url('../../assets/fonts/gt-america/GT-America-Standard-Thin.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/gt-america/GT-America-Standard-Thin.otf')
      format('truetype'),
    url('../../assets/fonts/gt-america/GT-America-Standard-Thin.ttf')
      format('truetype'),
    url('../../assets/fonts/gt-america/GT-America-Standard-Thin.woff2')
      format('woff2'),
    url('../../assets/fonts/gt-america/GT-America-Standard-Thin.woff')
      format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
